import { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { NavbarItems } from "./navbarItems";
import "./navbar.css";
import { IconContext } from "react-icons";

function Navbar() {
  const [showNavbar, setShowNavbar] = useState(false);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff", size: "25px" }}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars onClick={() => setShowNavbar(true)} />
          </Link>
        </div>
        <nav className={showNavbar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items">
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose onClick={() => setShowNavbar(false)} />
              </Link>
            </li>
            {NavbarItems.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path} onClick={() => setShowNavbar(false)}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
