export const NavbarItems = [
  {
    title: "Home",
    path: "/",
    cName: "nav-text",
  },
  {
    title: "About",
    path: "/about",
    cName: "nav-text",
  },
  {
    title: "Portfolio",
    path: "/portfolio",
    cName: "nav-text",
  },
  {
    title: "Contact",
    path: "/contact",
    cName: "nav-text",
  },
];
